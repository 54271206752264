import React, { useEffect } from "react";
import { navigate, useLocation } from "@reach/router";
import { graphql } from "gatsby";

export default ({
  data: {
    allSanityPdfDocument: { edges: nodes },
  },
}) => {
  const location = useLocation();
  const file = location.pathname.split("/")?.pop();

  useEffect(() => {
    if (
      file &&
      nodes.find(({ node }) => node.file?.asset?.localFile?.name == file)
    ) {

      const callbacks = [];

      // This promise acts as a 'worst case scenario' fallback that can happen
      // if something catastrophic happens, like the container ID being changed
      // unexpectedly, or if the GTM library fails to trigger its own internal
      // timeout.
      callbacks.push(
        new Promise((resolve, reject) => {
          setTimeout(() => {
            reject(
              new Error(
                "Something horrible has happened!  Check tag manager RIGHT NOW!",
              ),
            );
          }, 5000);
        }),
      );

      // This promise will resolve when the event callback is invoked by the
      // appropriate GTM container.
      callbacks.push(
        new Promise(function (resolve) {
          typeof window !== "undefined" &&
            window.dataLayer &&
            window.dataLayer.push({
              event: "download",
              downloadedFile: `shared - ${file}`,
              eventCallback: function (container_id) {
                // Only resolve if the correct container is finished executing tags.
                //console.log(container_id)
                if (["G-69KJ8WLVLH", "G-YM118X7L0T", "G-1FB8K0R489", "G-Z7K82MR43P"].includes(container_id)) {
                  resolve();
                }
              },
            });
        }),
      );

      Promise.race(callbacks).finally(() => {
        window.location.replace(`/static/${file}${window.location.search}`);
      });

      return null;
    } else {
      navigate("/404/");
    }
  });
  return null;
};

export const query = graphql`
  query {
    allSanityPdfDocument {
      edges {
        node {
          file {
            asset {
              localFile {
                name
              }
            }
          }
        }
      }
    }
  }
`;
